<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="mb-3"
      @on-complete="formSubmitted"
    >

      <!-- basic tab -->
      <tab-content
        title="Promotion Basic"
        :before-change="validationForm"
      >
        <validation-observer
          ref="basicRules"
          tag="form"
        >
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Activity Name"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Activity Name"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="activity.name"
                    :state="errors.length > 0 ? false:null"
                    oninput="if(value.length > 32)value = value.slice(0, 32)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Target Currency"
                label-for="target-currency"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Target Currency"
                  rules="required"
                >
                  <v-select
                    v-model="activity.target_currency"
                    input-id="target-currency"
                    :options="currencyOptions"
                    :clearable="false"
                    :searchable="false"
                    :reduce="val => val.value"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Type"
                label-for="type"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Type"
                  rules="required"
                >
                  <v-select
                    v-model="activity.type"
                    input-id="type"
                    :options="typeOptions"
                    :clearable="false"
                    :searchable="false"
                    :reduce="val => val.value"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Lower Bound"
                label-for="lower-bound"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Lower Bound"
                  rules="required"
                >
                  <b-form-input
                    id="lower-bound"
                    v-model="configDetail.lowerBound"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Upper Bound"
                label-for="upper-bound"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Upper Bound"
                  rules="required"
                >
                  <b-form-input
                    id="upper-bound"
                    v-model="configDetail.upperBound"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="activity.type === 2"
              md="12"
            >
              <b-form-group
                label="Percentage(%)"
                label-for="percentage"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Percentage"
                  rules="required"
                >
                  <b-form-input
                    id="percentage"
                    v-model="configDetail.numberValue"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="activity.type === 3"
              md="12"
            >
              <b-form-group
                label="Setting Value"
                label-for="setting-value"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Setting Value"
                  rules="required"
                >
                  <b-form-input
                    id="setting-value"
                    v-model="configDetail.strValue"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="activity.type !== 3"
              md="6"
            >
              <b-form-group
                label="Lower Limit"
                label-for="lower-limit"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Lower Limit"
                  rules="required|positive"
                >
                  <b-form-input
                    id="lower-limit"
                    v-model="configDetail.lowerLimit"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="activity.type !== 3"
              md="6"
            >
              <b-form-group
                label="Upper Limit"
                label-for="upper-limit"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Upper Limit"
                  rules="required|positive"
                >
                  <b-form-input
                    id="upper-limit"
                    v-model="configDetail.upperLimit"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Date & Time Range"
                label-for="dateTimeRange"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Date & Time Range"
                  rules="required"
                >
                  <date-picker
                    v-model="dateTimeRange"
                    value-type="format"
                    format="YYYY-MM-DD HH:mm:ss"
                    type="datetime"
                    range
                    confirm
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- Include -->
      <tab-content title="Include">
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Merchants"
              label-for="include-merchants"
            >
              <v-select
                id="include-merchants"
                v-model="includeMerchants"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="merchantOptions"
                :reduce="val => val.value"
                multiple
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="APPs"
              label-for="include-apps"
            >
              <v-select
                id="include-apps"
                v-model="includeApps"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="appOptions"
                :reduce="val => val.value"
                multiple
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Methods"
              label-for="include-methods"
            >
              <v-select
                id="include-methods"
                v-model="includeMethods"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="methodOptions"
                :reduce="val => val.value"
                multiple
              />
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>

      <!-- Exclude -->
      <tab-content title="Exclude">
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Merchants"
              label-for="exclude-merchants"
            >
              <v-select
                id="exclude-merchants"
                v-model="excludeMerchants"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="merchantOptions"
                :reduce="val => val.value"
                multiple
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="APPs"
              label-for="exclude-apps"
            >
              <v-select
                id="exclude-apps"
                v-model="excludeApps"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="appOptions"
                :reduce="val => val.value"
                multiple
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Methods"
              label-for="exclude-methods"
            >
              <v-select
                id="exclude-methods"
                v-model="excludeMethods"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="methodOptions"
                :reduce="val => val.value"
                multiple
              />
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>

    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { required, email, positive } from '@validations'
// import _ from 'lodash'
import usePromotionsJs from './promotions'

const {
  fetchMerchants,
  fetchApps,
  fetchElements,
  addPromotion,
} = usePromotionsJs()

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    DatePicker,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      merchantOptions: [],
      appOptions: [],
      methodOptions: [],
      includeMerchants: [],
      includeApps: [],
      includeMethods: [],
      excludeMerchants: [],
      excludeApps: [],
      excludeMethods: [],
      configDetail: {
        upperBound: -1,
        lowerBound: -1,
        numberValue: 0,
        upperLimit: -1,
        lowerLimit: -1,
        strValue: '',
      },
      activity: {
        id: '',
        name: '',
        target_currency: 'BRL',
        include_merchant: '',
        exclude_merchant: '',
        include_app: '',
        exclude_app: '',
        include_channel: '',
        exclude_channel: '',
        sponsor: 'USER',
        type: 1,
        config_detail: '~,~,~,~',
        priority: 1,
        is_exclusive: false,
        start_time: '',
        end_time: '',
      },
      dateTimeRange: [],
      currencyOptions: [],
      typeOptions: [
        { label: 'Reduction', value: 1 },
        { label: 'Rebate', value: 2 },
        { label: 'Fee Offer', value: 3 },
      ],

      required,
      email,
      positive,
    }
  },
  created() {
    this.loadOptions()
  },
  methods: {
    showAlert(icon, title, text) {
      this.$swal({
        icon,
        title,
        text,
        customClass: {
          confirmButton: `btn btn-${icon}`,
        },
      })
    },
    showToast(title, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon: 'BellIcon',
            text,
            variant: 'warning',
          },
        },
        {
          position: 'top-center',
        },
      )
    },
    loadOptions() {
      fetchMerchants(
        merchants => {
          this.merchantOptions = merchants
        },
        fail => {
          this.showToast('Warning', `Cannot get merchants with ${fail}`)
        },
      )
      fetchApps(
        apps => {
          this.appOptions = apps
        },
        fail => {
          this.showToast('Warning', `Cannot get APPs with ${fail}`)
        },
      )
      fetchElements(
        elements => {
          this.methodOptions = elements.methods
          this.currencyOptions = elements.currencies
        },
        fail => {
          this.showToast('Warning', `Cannot get Methods with ${fail}`)
        },
      )
    },
    formSubmitted() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          [this.activity.start_time, this.activity.end_time] = this.dateTimeRange
          this.activity.include_merchant = this.includeMerchants.length > 0 ? this.includeMerchants.join(',') : ''
          this.activity.include_app = this.includeApps.length > 0 ? this.includeApps.join(',') : ''
          this.activity.include_channel = this.includeMethods.length > 0 ? this.includeMethods.join(',') : ''
          this.activity.exclude_merchant = this.excludeMerchants.length > 0 ? this.excludeMerchants.join(',') : ''
          this.activity.exclude_app = this.excludeApps.length > 0 ? this.excludeApps.join(',') : ''
          this.activity.exclude_channel = this.excludeMethods.length > 0 ? this.excludeMethods.join(',') : ''

          let {
            lowerBound, upperBound, lowerLimit, upperLimit,
          } = this.configDetail
          const {
            numberValue, strValue,
          } = this.configDetail
          if (lowerBound < 0) {
            lowerBound = '~'
          }
          if (upperBound < 0) {
            upperBound = '~'
          }
          if (lowerLimit < 0) {
            lowerLimit = '~'
          }
          if (upperLimit < 0) {
            upperLimit = '~'
          }

          if (this.activity.type === 1) {
            this.activity.config_detail = `${lowerBound},${upperBound},${lowerLimit},${upperLimit}`
          } else if (this.activity.type === 2) {
            this.activity.config_detail = `${lowerBound},${upperBound},${numberValue},${lowerLimit},${upperLimit}`
          } else {
            this.activity.config_detail = strValue
          }

          addPromotion(
            this.activity,
            () => {
              this.showAlert('success', 'Success!', `New Promotion ${this.activity.name} Added.`)
              this.$router.push({ name: 'promotions' })
            },
            fail => {
              this.showAlert('warning', 'Fail!', `${fail}`)
            },
          )
        }
      })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.basicRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';

.mx-datepicker-range {
  width: 100% !important;
}
.mx-input {
  height: 37px;
}
</style>
